<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ $t('globalTrans.all') }} {{ $t('globalTrans.return') }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="date">{{ $t('globalTrans.startDate') }}</label>
                                            <input type="date" id="date" v-model="search.start_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="date">{{ $t('globalTrans.endDate') }}</label>
                                            <input type="date" id="date" v-model="search.end_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('globalTrans.id') }}</th>
                                                    <th>{{ $t('globalTrans.merchant') }}</th>
                                                    <th>{{ $t('globalTrans.date') }}</th>
                                                    <th>{{ $t('globalTrans.total_parcel') }}</th>
                                                    <th>{{ $t('globalTrans.rider') }}</th>
                                                    <th>{{ $t('globalTrans.status') }}</th>
                                                    <th>{{ $t('globalTrans.action') }}</th>
                                                </tr>
                                            </thead>
                                            <!-- <tfoot>
                                                <tr>
                                                    <th>{{ $t('globalTrans.id') }}</th>
                                                    <th>{{ $t('globalTrans.merchant') }}</th>
                                                    <th>{{ $t('globalTrans.date') }}</th>
                                                    <th>{{ $t('globalTrans.total_parcel') }}</th>
                                                    <th>{{ $t('globalTrans.rider') }}</th>
                                                    <th>{{ $t('globalTrans.status') }}</th>
                                                    <th>{{ $t('globalTrans.action') }}</th>
                                                </tr>
                                            </tfoot> -->
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>BHK-MR-{{ item.id }}</td>
                                                    <td>{{ item.merchant ? item.merchant.business : '' }}</td>
                                                    <td>{{ item.date | dateformat }}</td>
                                                    <td>{{ item.total_order }}</td>
                                                    <td>{{ item.rider ? (item.rider.name + ', ' + item.rider.mobile) : '' }}</td>
                                                    <td>{{ getStatus(item.status) }}</td>
                                                    <td>
                                                        <button class="btn btn-info btn-sm mr-1" @click="returnModal(item)" title="Returned"> <i class="fa fa-check"></i> </button>  
                                                        <button class="btn btn-primary btn-sm mr-1" @click="showOrder(item.id)" title="Show Order"> <i class="fa fa-eye"></i> </button>  
                                                        <button class="btn btn-success btn-sm"  @click="downloadPdf(item.id)" title="PDF"><i class="fa fa-download" aria-hidden="true"></i></button>                                                         
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>  
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="showReturnModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form v-on:keyup.enter="createPickup">
                    <b-overlay :show="loader">
                        <div class="modal-content">
                            <div class="modal-header bg-info text-white">
                                <h5 class="modal-title text-center w-100">Complete Return</h5>
                                <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                    <form @submit.prevent="handleSubmit(createPickup)">
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Total Parcel" vid="merchant" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="merchant">{{ $t('globalTrans.merchant') }} <span class="text-danger" title="Required">*</span></label>
                                                        <input type="text" id="merchant" v-model="form_edit.merchant.business" class="form-control" disabled>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div> 
                                                </ValidationProvider>                       
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Total Parcel" vid="total_parcel" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="total_parcel">{{ $t('globalTrans.total_parcel') }} <span class="text-danger" title="Required">*</span></label>
                                                        <input type="text" id="merchant" v-model="form_edit.total_order" class="form-control" disabled>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div> 
                                                </ValidationProvider>                       
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Return OTP" vid="return_otp" v-slot="{errors}" rules="required">
                                                    <div class="form-group">
                                                        <label for="return_otp">OTP <span class="text-danger" title="Required">*</span></label>
                                                        <input type="text" id="return_otp" v-model="form_edit.return_otp" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div> 
                                                </ValidationProvider>                       
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info" @click.prevent="returned">{{ $t('globalTrans.submit') }}</button>
                                <button type="button" class="btn btn-danger" @click="cancelModal">{{ $t('globalTrans.cancel') }}</button>
                            </div>
                        </div>
                    </b-overlay>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    import 'jspdf-autotable';
    import ExportPdf from './ReturnPdf'

    export default {
        name:'payment',
        data () {
            return {   
                loader: false,             
                loading: false,   
                showReturnModal: false,   
                summary: null,   
                form_edit: null,   
                search: {
                    rider_id: this.$store.state.authUser.id,
                    start_date: moment().subtract(31,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                },
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created () {
            this.loadData()
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            }
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            }
        },
        methods:{
            searchData () {
                this.loadData()
            },
            async loadData(){    
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })       
                const response = await config.getData('/rider/return/list', params)
                this.loader = false
                if (response.success){
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            showOrder (id) {
                this.$router.push({ name: 'return.show', query: { id: id } })
            },
            cancelModal(){
                this.showReturnModal = false;
                document.body.classList.remove("modal-open");
            },
            getStatus (status) {
                if (status == 1) {
                    return 'Pending'
                } else if (status == 2) {
                    return 'On way to Merchant'
                } else if (status == 3) {
                    return 'Merchant Received'
                }
            },
            async downloadPdf(itemId){
                this.loader = true
                const response = await config.getData(`/rider/return/details/${itemId}`)
                this.loader = false
                if (response.success) {
                    this.pdfDownload(response.data, response.merchant)   
                } else {                
                    this.$toast.error('Sorry, something went wrong')  
                }
            },
            pdfDownload(datas, merchant) {
                // this.loader = true
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.returnPdfDetails(base64Logo, datas, merchant)
                // this.loader = false
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            returnModal (item) {
                this.form_edit = Object.assign(item, { return_otp: ''})
                this.showReturnModal = true
                document.body.classList.add("modal-open");
            },
            async returned () {
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.postData(`/rider/return/merchant-receive`, this.form_edit)                
                this.loader = false
                if (response.success) {  
                    this.showReturnModal = false 
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
                this.$store.dispatch('stateReload', false)  
            }
        }
    }
</script>

<style>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    .mt-30 {
        margin-top: 30px !important;
    }

    @media only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {
	
		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}

        .table-sm th, .table-sm td {
            padding: 0.3rem;
            padding-left: 50%;
        }
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { 
            border: 1px solid #ccc;
            margin-bottom: 7px; 
        }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 50%; 
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
		}
		
		/*
		Label the data
		*/
		td:nth-of-type(1):before { content: "আইডি" }
		td:nth-of-type(2):before { content: "তারিখ"; }
		td:nth-of-type(3):before { content: "মোট পার্সেল"; }
		td:nth-of-type(4):before { content: "চালক"; }
		td:nth-of-type(5):before { content: "ওটিপি"; }
		td:nth-of-type(6):before { content: "স্ট্যাটাস"; }
		td:nth-of-type(7):before { content: "অ্যাকশন"; }
	}
	
	/* Smartphones (portrait and landscape) ----------- */
	@media only screen
	and (min-device-width : 320px)
	and (max-device-width : 480px) {
		body { 
			padding: 0; 
			margin: 0; 
			width: 320px; }
		}
	
	/* iPads (portrait and landscape) ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		body { 
			width: 495px; 
		}
	}
</style>
